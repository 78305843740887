.cart-dropdown {
  position: absolute;
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 40px;
  z-index: 5;

  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
  }

  button {
    margin-top: auto;
  }
}
.empty-msg {
  font-size: 18px;
  font-weight: bolder;
}
.empty {
  height: 240px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(161, 161, 161);
}
