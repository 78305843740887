.sign-in {
  width: 400px;
  display: flex;
  flex-direction: column;
  .title-head {
    margin: 10px 0;
  }
}
.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
