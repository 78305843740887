.collection-page {
  display: flex;
  flex-direction: column;

  .collection-title {
    font-size: 38px;
    margin: 0 auto 30px;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    justify-content: space-between;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;

    & .collection-item {
      margin-bottom: 30px;
    }
  }
}
