.collection-item {
  width: 20vw;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;

  .image {
    width: 100%;
    height: 95%;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
    transition: 1s linear;
    border-radius: 15px;
  }

  .custom-button {
    width: 80%;
    position: absolute;
    top: 255px;
    display: none;

    &:active {
      transition: 0.1s;
      color: black;
      background-color: #fff;
    }
  }
  &:hover {
    .image {
      opacity: 0.7;
    }
    .custom-button {
      display: flex;
      opacity: 0.8;
    }
  }

  .collection-footer {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    position: relative;

    .name {
      width: 90%;
      margin-bottom: 15px;
    }

    .price {
      width: 10%;
    }
  }
}
