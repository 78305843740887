.contact-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-title {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    margin-top: 7px;
  }
}
h5 {
  padding: 0;
  margin: 0;
}
.form-control {
  width: 50%;
  justify-content: center;
}
.cust-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0 0;
}
.message {
  display: flex;
  margin: 0px 0px 10px 5px;
}
textarea {
  text-indent: 30px;
}
