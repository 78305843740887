.cart-item {
  width: 100%;
  display: flex;
  height: 80px;
  margin-bottom: 15px;

  img {
    width: 27%;
    border-radius: 10px;
  }

  .item-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;

    .name {
      font-size: 16px;
      font-weight: bolder;
    }
  }
}
.custom-button {
  border-radius: 5px;
  transition: 0.25s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}
.far {
  color: black;
  font-size: 20px;
  &:hover {
    // background-color: rgb(204, 204, 204);
    // border-radius: 10px;
    text-shadow: 0px 0px 2px grey;
  }
}
