.checkout-page {
  width: 70%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;

  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    .header-block {
      text-transform: capitalize;
      width: 20%;
      display: flex;
      justify-content: center;
      &:hover {
        text-shadow: 0px 0px 1px grey;
        .fas {
          color: black;
        }
      }

      &:last-child {
        width: 14%;
      }
    }
  }

  .total {
    margin-top: 30px;
    margin-left: auto;
    font-size: 40px;
    font-weight: bolder;
  }
}
span {
  font-weight: bolder;
  font-size: 16px;
}
i {
  font-size: 10px;
  color: green;
}
.fas {
  font-size: 18px;
  color: grey;
  padding: 0 8px;
  transition: 0.25s ease-in-out;
}
.stripe-payment {
  color: red;
  text-align: center;
  margin: 40px;
  font-size: 24px;
}
