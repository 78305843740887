.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.logo-container {
  height: 100%;
  width: 70px;
  padding: 25px;
}

.options {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.option {
  padding: 10px 15px;
  font-size: 20px;
}
.user-icon {
  display: inline-block;
  font-size: 46px !important;
}
.logo-title {
  font-weight: bolder;
}
.logo-title:hover {
  font-weight: normal;
}
.profile-img {
  width: 50%;
  border-radius: 100%;
}
.option:hover {
  cursor: pointer;
  font-weight: bolder;
}
.profile-name {
  background-color: rgba(136, 136, 136, 0.3);
  font-size: 10px;
  width: auto;
  padding: 0px 10px;
  position: absolute;
  top: 12.3%;
  right: 8%;
  word-spacing: 0.3rem;
  border-radius: 10px;
  font-weight: bolder;
}
