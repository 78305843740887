.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  font-size: 20px;
  padding: 18px 0;
  justify-content: space-between;
  margin: 0 10px;
  align-items: center;

  .image-container {
    width: 20%;
    display: flex;
    justify-content: center;

    img {
      width: 90%;
      border-radius: 10px;
    }
  }
  .name,
  .quantity,
  .price {
    width: 20%;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  .quantity {
    display: flex;
    .arrow {
      cursor: pointer;
      font-size: 18px;
      &:first-child {
        transform: rotate(-90deg);
      }
      &:last-child {
        transform: rotate(90deg);
      }
      &:hover {
        .fas {
          color: black;
          text-shadow: 0 0 1px black;
        }
      }
      &:active {
        .fas {
          color: grey;
          transform: scale(1.2);
        }
      }
    }
    .value {
      width: 15%;
      margin: 0 15px;
      display: flex;
      justify-content: center;
    }
  }

  .remove-button {
    width: 14%;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
}
