body {
  font-family: "Montserrat", sans-serif;
  padding: 20px 60px;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  font-weight: bolder;
}

* {
  box-sizing: border-box;
}
